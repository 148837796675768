const configuration = {
    API_BASE_URL: 'https://in-service.alpide.com',
    AWS_BASE_URL: 'https://in-service.alpide.com',
    PDF_API_BASE_URL: 'https://doc.alpidedev.com/api/',
    INVOICE_SERVICE_BASE_URL: 'https://in-service.alpide.com',
    PAYMENT_GATEWAY_BASE_URL: 'https://in-service.alpide.com',
    PLAID_BASE_URL: 'https://in-service.alpide.com',
    UI_BASE_URL: 'https://in-app.alpide.com/',
    CALLBACK_URI_MARKTPLACE_AMAZON: 'https://in-app.alpide.com/admin/sales-channels-amazon',
    CALLBACK_URI_MARKTPLACE_EBAY: 'https://in-app.alpide.com/admin/sales-channels-ebay',
    CALLBACK_URI_AUTH_NET: 'https://in-app.alpide.com/admin/payment-integration',

    PAYMENT_SERVER_BASE_URL: 'http://us-app.alpide.com',
    ENCRYPT_SECRET: 'Unl0ck!!!',
    API_VERSION: '',
    PDF_API_VERSION: 'v1',

    PAYMENT_FORM_ACTION: 'https://accept.authorize.net/payment/payment',

    rootContext: 'alpide',
    rootContextInvoiceService: 'alpide-invoice',
    rootContextPaymentGateway: 'alpide-paypal',
    rootContextPlaid: 'alpide-plaid',
    rootContextTwilio: 'alpide-tcom',
    rootContextSMS: 'alpide-sms',
    rootContextCC:'alpide-cc',
    rootContextRazorpay:'alpide-razorpay',
    rootContextSupport:'alpide-support',
    rootContextStripe: 'alpide-stripe',
    rootContextHRMS: 'alpide',
    rootContextCMS: 'alpide-cms',
    rootContextEasyPost: 'alpide-ep',
    rootContextShiprocket: 'alpide-shiprocket',
    API_VERSION_CMS:'api/v1',
    API_VERSION_SHIPROCKET:'api/v1',
    CMS_BASE_URL: 'https://cms-service.alpide.com',
    CB_PUBLIC_KEY: 'test_9KdLB3laV1fzmNupMYMQyQRwUioTPYVL',
    CC_TOKENIZER_URL: 'https://fts.cardconnect.com/itoke/ajax-tokenizer.html',
    SSO_URL: 'https://accounts.alpide.com',
    ROOT_DOMAIN: 'alpide.com',
    WP_DOMAIN: 'https://login.alpide.com',
    KIOSK_BASE_URL: 'https://in-donate.alpide.com',
    STRIPE_PUBLISHABLE_KEY: 'pk_live_51PsiasP9XnPbaJwwJIsqxadMslrXVm2MOVY1yd3SzQTb1HnY2OQYQKCCvahgFRC1vOm6XoZKCVYKZr07G2mGkvoO00j6qayfDe',
    BUCKET_NAME: {
        BO_RELATIONSHIP: 'alpide-prd-us1-relationship',
        BO_SALES_INQUIRY: "alpide-prd-us1-inquiry",
        BO_SALES_QUOTE: "alpide-prd-us1-sales-quote",
        BO_SALES_ORDER: 'alpide-prd-us1-sales-order',
        BO_CUSTOMER_INVOICE: "alpide-prd-us1-sales-invoice",
        BO_CREDIT_MEMO: "alpide-prd-us1-credit-memo",
        BO_PURCHASE_REQUEST: "alpide-prd-us1-purchase-request",
        BO_RFQ: "alpide-prd-us1-supplier-rfq",
        BO_PURCHASE_ORDER: "alpide-prd-us1-purchase-order",
        BO_INBOUND_DELIVERY: "alpide-prd-us1-inbound-delivery",
        BO_SUPPLIER_INVOICE: "alpide-prd-us1-purchase-invoice",
        BO_DEBIT_MEMO: "alpide-prd-us1-debit-memo",
        BO_INVENTORY: "alpide-prd-us1-inventory"
    },
    ERP_THEMES:{
        64: "Vogue",
        37: "Dapper",
        41: "Matrikala"
    },
    RAZORPAY_KEYS: {
        56: "rzp_live_ib6x5GoFPaYWei",
    }

};

export default configuration;
